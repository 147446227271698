<template lang="pug">
  div(
    style="margin-bottom:10px;"
    )
      div(
        class="vx-row"
      )
        vs-col(
          vs-type='flex'
          vs-justify='center'
          vs-align='left'
          vs-w='6'
          style="margin-left: 22%;")
          vx-card
            vs-row
              vs-col(
                  vs-type='flex'
                  vs-justify='center'
                  vs-align='center'
                  vs-w='7')
                vs-input.w-full(
                  name='name'
                  v-validate="'required'"
                  v-model="roleToSave.name"
                  :label-placeholder="$t('roles.name')"
                  :data-vv-as="$t('roles.name')"
                  icon-pack="feather"
                  icon="icon icon-plus"
                )
              vs-col(
                vs-type='flex'
                vs-justify='center'
                vs-align='center'
                vs-w='5')
                  vs-button.inline.sm-input.buttom-cancel(
                    style="height: 39px; top: 8px;"
                    color='primary'
                    type='filled'
                    @click='save')
                    | {{$t('devices.save')}}
            vs-row
              vs-col(
                vs-type='flex'
                vs-justify='center'
                )
              span.text-danger.text-sm(
                v-show="errors.has('name')"
                style="padding-left: 17px;padding-top: 8px;"
                ) {{ errors.first('name') }}
      .mt-4
      div(
        class="vx-row"
        style="margin-bottom:10px;"
      )
        vs-col(
          vs-w='6'
          style="margin-bottom:5px;margin-left: 22%;")
            vs-row
              vs-col(
                vs-type='flex' 
                vs-justify='center'
                )
              span.text-danger.text-sm(
                v-show="errors.has('permission')"
                style="padding-left: 17px;padding-top: 8px;"
                ) {{ errors.first('permission') }}
            .mt-2
            vs-row
              treeselect(
                name="permission" 
                :multiple="true"
                :options="options"
                :placeholder="$t('role_permissions.option')"
                v-model="value"
                v-validate="'required'"
                :disable-branch-nodes="true"
                :flatten-search-results="true"
                :always-open="true"
                openDirection="bottom"
              )
                div(
                  slot="value-label"
                  slot-scope="{ node }"
                ) {{ $t(node.raw.label) }}
                label(
                  slot="option-label"
                  slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                  :class="labelClassName"
                ) {{ $t(node.label) }}
</template>
<script>
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import rolesService from "@/api/roles/roles"
import { validatePermission } from "@/Ability"

export default {
  components: {
    treeselect: Treeselect
  },
  data() {
    return {
      roleToSave: {
        id: null,
        name: null
      },
      apiUrl: process.env.VUE_APP_URL_API + "/roles/",
      value: [],
      options: [],
      permissionsByRole: null
    }
  },
  methods: {
    getCurrentRole() {
      let currentRole = JSON.parse(window.localStorage.getItem("currentRole"))
      if (!currentRole) {
        currentRole = this.$store.getters["roles/currentRole"]
      }
      if (this.$route.params.id) {
        validatePermission(this, "update", "roles", "Home", () =>
          this.setRoleToSave(currentRole)
        )
      } else {
        validatePermission(this, "read", "roles", "Home", () =>
          this.setRoleToSave(currentRole)
        )
      }
    },
    setRoleToSave(currentRole) {
      this.roleToSave = currentRole
    },
    goToRoles() {
      this.$vs.loading.close()
      this.$vs.notify({
        text: this.$t("Depots.petition"),
        color: "#64cc52",
        iconPack: "feather",
        icon: "icon-check-circle"
      })
      this.$router.push("/roles")
    },
    getRoles() {
      var permissionsCategorized = null
      if (this.$route.params.id) {
        rolesService.getRolPermission(this.$route.params.id).then(result => {
          this.permissionsByRole = result.data
          rolesService.getAllRoles().then(result => {
            permissionsCategorized = this.getCategoriesWithPermissions(result.data)
            this.options = getOptionStructure(permissionsCategorized)
          })
        })
      } else {
        rolesService.getAllRoles().then(result => {
          permissionsCategorized = this.getCategoriesWithPermissions(result.data)
          this.options = getOptionStructure(permissionsCategorized)
        })
      }
    },
    getCategoriesWithPermissions(permissions) {
      let permissionsCategorized = {}
      for (let i=0; i < permissions.length; i++) {
        let codenameSplit = permissions[i].slug.split('_')
        let subject = codenameSplit[1]
        let action = codenameSplit[0]//eslint-disable-line
        let category = `roles.${subject.toLowerCase()}`
        let permissionOption = {
          id: permissions[i].id,
          label: `roles.${permissions[i].slug}`
        }
        if (permissionsCategorized[category]) {
          permissionsCategorized[category].push(permissionOption)
        } else {
          permissionsCategorized[category] = [permissionOption]
        }
      }
      if (this.permissionsByRole != null) {
        for (let j=0; j < this.permissionsByRole.length; j++) {
          let findedPermission = permissions.find(
            permissionsRol => permissionsRol.id === this.permissionsByRole[j].id,
          )
          if (findedPermission) {
            this.value.push(findedPermission.id)
          }
        }
      }
      return permissionsCategorized
    },
    save() {
      this.$validator.validate().then(result => {
        if (result) {
          if (this.roleToSave.id) {
            this.putRole()
          } else {
            this.postRole()
          }
        }
      })
    },
    postRole() {
      var role = {
        name: this.roleToSave.name,
        permissions: this.value
      }
      this.$swal({
        position: "center",
        type: "info",
        title: this.$t("message_confir.title_create_role"),
        text: this.$t("message_confir.create_role"),
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        confirmButtonText: this.$t("done-text"),
        cancelButtonText: this.$t("cancel-text"),
        showCancelButton: true,
        showConfirmButton: true
      }).then(result => {
        if (result.value) {
          this.$vs.loading()
          rolesService.createRole(role).then(this.goToRoles)
        }
      })
    },
    putRole() {
      var role = {
        name: this.roleToSave.name,
        permissions: this.value,
        id: this.roleToSave.id
      }
      this.$swal({
        position: "center",
        type: "info",
        title: this.$t("message_confir.title_edit_role"),
        text: this.$t("message_confir.edit_role"),
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        confirmButtonText: this.$t("done-text"),
        cancelButtonText: this.$t("cancel-text"),
        showCancelButton: true,
        showConfirmButton: true
      }).then(result => {
        if (result.value) {
          this.$vs.loading()
          rolesService.updateRole(role).then(this.goToRoles)
        }
      })
    }
  },
  created() {
    this.getCurrentRole()
    setTimeout(() => {
      this.getRoles()
    }, 500)
  },
  destroyed() {
    this.$store.dispatch("roles/set_roles", null)
  }
}

function getOptionStructure(permissionsCategorized) {
  let options = []
  for (const category in permissionsCategorized) {
    options.push({
      id: category,
      label: category,
      children: permissionsCategorized[category]
    })
  }
  return options
}
</script>
<style>
.vue-treeselect__multi-value {
  max-height: calc(12vh) !important;
  overflow: auto;
}

.vue-treeselect__multi-value-label {
  font-size: 10px;
  padding: 1px;
}
.vue-treeselect__menu {
  margin-bottom: 20px;
  max-height: calc(74vh - 18rem) !important;
}
</style>