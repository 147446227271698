<template lang="pug">
  form-roles
</template>

<script>

import FormRoles from '@/components/roles/Form'

const COMPONENT_NAME= "FormRoles"

export default {
  name:COMPONENT_NAME,
  components: {
      "form-roles":FormRoles
    }
  }
</script>