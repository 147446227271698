import HTTP from '@/http-common.js'

export default {
  getAllRoles(){
    HTTP.setHeader(true)
    let request = [
      `/permissions/permissions`
    ]
    return new Promise((resolve, reject) => {
      HTTP.get(...request).then(resolve).catch(reject)
    })
  },
  getRolPermission(id_rol) {
    HTTP.setHeader(true)
    let request = [
      `/v1/roles/roles/${id_rol}`
    ]
    return HTTP.post(...request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error.response))
  },
  getAllRolesData(){
    HTTP.setHeader(true)
    let request = [
      `v1/roles/roles`
    ]
    return new Promise((resolve, reject) => {
      HTTP.get(...request).then(resolve).catch(reject)
    })
  },
  createRole(data) {
    HTTP.setHeader(true)
    let request = [
      '/v1/roles/create',
      data
    ]
    return new Promise((resolve, reject) => {
      HTTP.post(...request).then(resolve).catch(reject)
    })
  },

  updateRole(data) {
    HTTP.setHeader(true)
    let request = [
      `/v1/roles/update/${data.id}`,
      data
    ]
    return new Promise((resolve, reject) => {
      HTTP.put(...request).then(resolve).catch(reject)
    })
  },
  
  deleteRole(id) {
    HTTP.setHeader(true)
    let request = [
      `/v1/roles/delete/${id}`
    ]
    return new Promise((resolve, reject) => {
      HTTP.delete(...request).then(resolve).catch(reject)
    })
  }

}